import { useRouter } from 'next/router'
import Script from 'next/script'
import nookies, { parseCookies } from 'nookies'
import React, { useEffect } from 'react'
import ga from 'src/utils/gtag'
import config from '../../src/config'
import {
  ClarityScript,
  ConsoleMessage,
  FacebookScript,
  GtagScript
} from '../../src/constants/scripts'
import fbp from '../../src/utils/fbp'

function Scripts() {
  const router = useRouter()

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(ConsoleMessage)
  }, [])

  useEffect(() => {
    fbp.pageview()

    const handleRouteChange = (url: URL) => {
      ga.pageview(url)
      fbp.pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    const cookies = parseCookies()
    const sessionId = cookies.session_id
    const maxAge = 60 * 60 * 24 * 30
    if (!sessionId) {
      nookies.set(null, 'session_id', Date.now().toString(), {
        maxAge,
        path: '/'
      })
    } else {
      nookies.set(null, 'session_id', sessionId, { maxAge, path: '/' })
    }
  }, [])

  if (!config.isProd) return null

  return (
    <>
      <Script
        id="gtag"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${config.pixels.ga}`}
      />
      <Script
        id="gtag-script"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{ __html: GtagScript }}
      />
      <Script
        id="microsoft-clarity"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{ __html: ClarityScript }}
      />
      <Script
        id="fb-pixel"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{ __html: FacebookScript }}
      />
      <Script
        id="hs-script-loader"
        strategy="lazyOnload"
        type="text/javascript"
        src="//js.hs-scripts.com/47956675.js"
      />
    </>
  )
}

export default React.memo(Scripts)
